import { showMessage } from 'react-native-flash-message';

export type ReloadMessageTypes = 'LOGIN_EXPIRED' | 'MISCONFIGURATION_DETECTED';

export const showReloadMessage = ({
  reloadMessageType = 'MISCONFIGURATION_DETECTED',
  timeout = 5000,
}: {
  reloadMessageType: ReloadMessageTypes;
  timeout: number;
}) => {
  const seconds = Math.round(timeout / 1000);

  if (reloadMessageType === 'LOGIN_EXPIRED') {
    showMessage({
      message: 'Login expired',
      description: `You Login has expired. Reloading the app in ${seconds} seconds.`,
      type: 'warning',
    });
  } else {
    showMessage({
      message: 'Something went off while you were away',
      description: `but no worries—the app refreshes in ${seconds} seconds!`,
      type: 'warning',
    });
  }
};
